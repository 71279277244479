/* 감지시 나타나는 효과 CSS */
.fadeIn {
  transform: translateY(10px);
  opacity: 0;
  transition: all 1s;
  transition-delay: 0.2s;
  animation: fadeInAnimation 1s ease-in-out;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn.visible {
  transform: translateY(0px);
  opacity: 1;
}

@font-face {
  font-family: 'NanumSquare';
  src: url('/public/font/nanum/NanumSquareRoundR.ttf') format('truetype');
  /* src: url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css')
    format('truetype'); */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HannaPro';
  src: url('/public/font/hanna/BMHANNAPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BMHANNAPro';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_seven@1.0/BMHANNAPro.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MICEGothic Bold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2206-01@1.0/MICEGothic Bold.woff2')
    format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NEXON Lv1 Gothic OTF';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv1 Gothic OTF.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 700;
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.eot');
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.eot?#iefix')
      format('embedded-opentype'),
    url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.woff')
      format('woff'),
    url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Jeju Gothic';
  src: url('//fonts.googleapis.com/earlyaccess/jejugothic.css');
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  src: url('/public/font/AppleSD/AppleSDGothicNeoR.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  justify-content: center;
  min-height: 100vh; /* 최소 높이를 화면 높이만큼으로 설정 */
  margin: 0; /* 기본 마진 제거 */
  font-family: 'Apple SD Gothic Neo', sans-serif;
  background-color: #f2f2f2;
}

.section1,
.section2,
.section3,
.section4,
.section5 {
  font-family: 'NEXON Lv1 Gothic OTF', sans-serif;
}

.App {
  background-color: #f2f2f2;
  /* margin: 0 5%; */
}

.header {
  background-color: #ede0d1;
  height: 110px;
}

.header .inner .logo2 img {
  width: 300px;
}

/* ... 나머지 스타일링 ... */

.dashboard {
  /* background-color: #ede0d1; */
}

.dashboard .swiper {
  height: 300px;
}
.dashboard .swiper img {
  width: 100%;
  height: auto;
}

.dashboard .section1 {
  position: relative;
  overflow: hidden;
}

.dashboard .section1 img {
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.dashboard .name {
  position: absolute;
  top: 50px;
  right: 100px;
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  z-index: 3;
  text-shadow: 1px 1px 3px grey;
}

.dashboard .name p {
  /* background-color: rgb(237, 224, 209, 0.5); */
  color: #fff;
  /* padding: 5px 10px; */
  font-size: 1.5em;
  margin: 0px;
  width: fit-content;
  border-radius: 10px;
  margin-top: 5px;
}

.dashboard .section2 {
  text-align: center;
  padding: 40px 5%;
  background-color: #f2f2f2;
  font-family: 'NEXON Lv1 Gothic OTF', sans-serif;
}

.dashboard .section2 .sub-title {
  padding-bottom: 50px;
  border-bottom: 1px solid #b68e5f;
}

.dashboard .section2 .sub-title strong {
  font-size: 2em;
}

.dashboard .section2 strong {
  font-size: 1.2em;
  font-weight: bold;
  display: block;
  padding: 20px 0;
  font-family: 'NEXON Lv1 Gothic OTF', sans-serif;
}

.dashboard .section2 a {
  background-color: f2f2f2#fff;
  color: #533c34;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid #533c34;
  font-size: 1rem;
}

.dashboard .section2 .content-wrap {
  padding: 50px 20%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0px;
}

.dashboard .section2 .content {
  flex: 1;
}

.dashboard .section2 .content svg {
  cursor: pointer;
  height: 80px;
}

.dashboard .section2 .content p {
  margin: 5px 0;
}

.dashboard .section3 {
  text-align: center;
  padding: 20px 0%;
  background-color: #f2f2f2;
  font-family: 'NEXON Lv1 Gothic OTF', sans-serif;
  position: relative;
}

.dashboard .section3 .sub-title {
  position: relative;
  padding: 5% 0;
  z-index: 4;
}

.dashboard .section3 .sub-title strong {
  font-size: 1.5em;
}

.dashboard .section3 .sub-title strong > span {
  color: #533c34;
}

.dashboard .section3 .sub-title p {
  font-size: 1.4em;
}

.dashboard .section3 .swiper {
  z-index: 3;
}

.dashboard .section3 .swiper img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* 애니메이션 효과 적용 */
}

.dashboard .section3 .swiper-slide:hover img {
  transform: translateY(-15px); /* 이미지를 10픽셀 위로 이동 */
}

.dashboard .section3 .overlay {
  position: absolute;
  background: #e5e5e5;
  width: 80%;
  height: 100%;
  z-index: 2;
  top: 0;
  margin: 0 10%;
  border-radius: 20px;
}

.dashboard .section3 .overlay2 {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 15%;
  background: #f2f2f2;
  z-index: 5;
}

.dashboard .section4 {
  text-align: center;
  padding: 5%;
  background-color: #f2f2f2;
}

.dashboard .section4 .sub-title {
  padding-bottom: 5%;
  display: flex;
  gap: 20px;
}

.dashboard .section4 .sub-title .left,
.dashboard .section4 .sub-title .right {
  border-top: 3px solid #533c34;
  padding: 20px;
  flex: 3;
  margin-top: 10px;
}

.dashboard .section4 .sub-title strong {
  flex: 4;
  font-size: 1.6em;
  background-color: #533c34;
  padding: 5px 150px;
  border-radius: 10px;
  color: #f2f2f2;
}

.dashboard .section4 .sub-title p {
  font-size: 0.9em;
}

.dashboard .section4 .sub-title div > p > span {
  color: red;
  font-weight: bold;
}

.dashboard .section4 .contents {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard .section4 .swiper {
  padding: 0 10%;
}

.dashboard .section4 .swiper .contents .left {
  text-align: right;
  /* padding-left: 20%; */
  width: 30%;
}

.dashboard .section4 .swiper .contents .left .title {
  font-size: 1.5rem;
  color: #533c34;
}

.dashboard .section4 .swiper .contents .left h1 {
  font-size: 1.8rem;
  color: red;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: 'Jeju Gothic', sans-serif;
}

.dashboard .section4 .swiper .contents .left P {
  margin-top: 20px;
  font-size: 0.9rem;
}

.dashboard .section4 .swiper .contents .left > div {
  width: 100%;
}

.dashboard .section4 .swiper .right {
  width: 40%;
}

.dashboard .section4 .swiper .contents .right img {
  padding-left: 10%;
  width: 100%;
  height: auto;
}

.sideFlow {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
}

.sideFlow ul {
  height: 190px;
}

.sideFlow ul li {
  margin-bottom: 10px;
}

.sideFlow ul li > button {
  box-shadow: 15px 15px 30px 0px rgba(50, 30, 25, 0.2);
  transition: background-color 0.4s;
  border-radius: 30px;
  background-color: #fff;
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: none;
  padding: 0;
}

.sideFlow ul li > button i {
  display: block;
  margin-bottom: 5px;
  position: relative;
}
.sideFlow ul li > button strong {
  font-size: 15px;
  font-weight: 600;
  color: #533c34;
  letter-spacing: -0.025em;
}

.modal {
  display: fixed;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content-findStore {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%);
  width: 300px;
}

.modal-content-findStore .frm {
  background-color: #fff;
  text-align: center;
  justify-content: center;
}

.modal-content-findStore ul {
  padding-left: 0px;
  width: 100%;
}

.modal-content-findStore li {
  margin: 10px auto;
}

.modal-content-findStore select {
  width: 100%;
  height: 35px;
  border: 1px gray solid;
  border-radius: 10px;
  padding-left: 5px;
  color: #333;
}

.modal-content-findStore .store-list {
  border: 1px gray solid;
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal-content-findStore .store-list p {
  text-align: left;
  padding-left: 8px;
  overflow-wrap: break-word;
  margin: 0;
  font-size: 13px;
}

.modal-content-findStore .submit button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #a97550;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
}

.modal-content-inquiry {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%);
}

.modal-content-inquiry .chk_w {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.modal-content-inquiry .chk_w .chk_btn {
  width: 24px;
}

.modal-content-inquiry .frm {
  background-color: #fff;
  text-align: center;
  justify-content: center;
}

.modal-content-inquiry ul {
  padding-left: 0px;
  width: 100%;
}

.modal-content-inquiry li {
  margin: 10px auto;
}

.modal-content-inquiry input {
  width: 95%;
  height: 30px;
  border: 1px gray solid;
  border-radius: 10px;
  padding-left: 10px;
}

.modal-content-inquiry select {
  width: 100%;
  height: 35px;
  border: 1px gray solid;
  border-radius: 10px;
  padding-left: 5px;
  color: #333;
}

.modal-content-inquiry .chk {
  margin: 10px;
}

.modal-content-inquiry .chk .detail {
  cursor: pointer;
  padding-left: 5px;
}

.modal-content-inquiry .chk input {
  width: 30px;
  height: 15px;
}

.modal-content-inquiry .submit button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #a97550;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.modal-content-agree {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 70%;
}

.modal-content-agree > p {
  font-size: 14px;
}

.modal-content-agree .close {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
}

.modal-content-agree .content {
  height: 500px;
  border: 1px solid black;
  border-radius: 5px;
  overflow-y: scroll;
  padding: 10px;
  font-size: 12px;
}

.brand-title {
  position: relative;
  height: 350px;
  /* background-color: gray; */
}

.brand-title img {
  width: 100%; /* 이미지의 너비를 div의 너비에 맞춤 */
  height: 350px;
  object-fit: cover;
  opacity: 0.75;
}

.brand-title .ment {
  position: absolute;
  color: #fff;
  left: 10%;
  top: 40%;
  font-size: 30px;
  color: black;
  text-shadow: 1px 1px 3px grey;
}

.inner {
  margin: 0 auto;
  text-align: center;
}

.inner .section1 {
  font-size: 30px;
  padding: 30px 0;
  margin: 0 5%;
  border-bottom: 1px solid #b68e5f;
}

.intro .inner .section2 {
  display: flex;
  align-items: stretch;
  padding: 5%;
}

.brandIntroduce .inner {
  max-width: 100% !important;
}

.brandIntroduce .inner .section1 {
  border: none;
}

.brandIntroduce .section2 {
  position: relative;
}

.brandIntroduce .section2 img {
  width: 100%;
  height: auto;
}

.brandIntroduce .section2 p {
  position: absolute;
  top: 20%;
  left: 15%;
  font-size: 1.5vw;
  font-weight: bold;
  text-align: left;
  color: #322017;
  word-spacing: -1px;
  font-family: 'Apple SD Gothic Neo', sans-serif;
}

.brandIntroduce .section3 {
  position: relative;
  padding: 0;
}

.brandIntroduce .section3 img {
  width: 100%;
  height: auto;
}

.brandIntroduce .section3 p {
  position: absolute;
  top: 25%;
  left: 5%;
  font-size: 40px;
  font-weight: 500;
  text-align: left;
  color: #533c34;
}

.brandIntroduce .section3 p span {
  font-weight: 800;
  font-size: 45px;
}

.brandIntroduce .section4 {
  position: relative;
  padding-top: 0;
}

.brandIntroduce .section4 img {
  width: 100%;
  height: auto;
}

.brandIntroduce .section4 .ment1 {
  position: absolute;
  top: 15%;
  left: 10%;
  color: #fff;
}

.brandIntroduce .section4 .ment1 p {
  margin: 0;
  font-size: 150px;
  font-family: playfairdisplay-bold, playfair display, serif;
}

.brandIntroduce .section4 .ment2 {
  position: absolute;
  top: 35%;
  left: 23%;
  color: #fff;
}

.brandIntroduce .section4 .ment2 p {
  margin: 0;
  font-size: 150px;
  font-family: playfairdisplay-bold, playfair display, serif;
}
.brandIntroduce .section4 .ment3 {
  position: absolute;
  top: 20%;
  right: 10%;
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.brandIntroduce .section4 .ment3 .big {
  font-size: 25px;
}

.brandIntroduce .section4 .ment3 p {
  margin: 0;
}

.brandIntroduce .section5 {
  padding: 50px 0;
  display: flex;
  align-items: center; /* 세로 가운데 정렬 */
}

.brandIntroduce .section5 .left,
.brandIntroduce .section5 .right {
  flex: 1; /* 각 섹션에게 동일한 유연성을 부여 */
  width: 50%; /* 각 섹션의 너비를 50%로 설정 */
}
.brandIntroduce .section5 .left img {
  width: 80%;
  height: auto;
}

.brandIntroduce .section5 .right p {
  text-align: left;
  font-size: 23px;
  color: #533c34;
}

.brandIntroduce .section5 .right p span {
  font-weight: 700;
  font-size: 25px;
}

.bi .inner .section2 {
  padding: 50px 0;
  font-weight: 500;
}

.bi .inner .section2 strong {
  font-size: 25px;
}

.bi .inner .section2 strong strong {
  padding-left: 20px;
  font-size: 40px;
}

.bi .inner .section3 {
  height: 500px;
  display: flex; /* Flexbox를 사용하여 자식 요소들을 가로로 배치 */
  justify-content: space-around; /* 좌우 여백을 균등하게 설정 */
  padding: 50px;
}

.bi .inner .section3 > div {
  background-image: url('/public/img/monoon.png'); /* 배경 이미지 설정 */
  width: 45%; /* 각 div의 너비를 창의 절반 크기로 설정 */
  position: relative; /* 이미지를 포지셔닝하기 위해 relative 설정 */
  display: flex; /* Flexbox를 사용하여 중앙 정렬 */
  justify-content: center; /* 가로 방향 중앙 정렬 */
  align-items: center; /* 세로 방향 중앙 정렬 */
  border: 3px solid #a97550;
  border-radius: 20px;
}

.bi .inner .section3 img {
  max-width: 50%; /* 이미지가 div의 전체 너비를 넘지 않도록 설정 */
  max-height: 50%; /* 이미지가 div의 전체 높이를 넘지 않도록 설정 */
  position: absolute; /* 이미지를 div 내에서 절대 위치로 설정 */
  top: 50%; /* 이미지를 div의 중앙에 정렬 */
  left: 50%; /* 이미지를 div의 중앙에 정렬 */
  transform: translate(-50%, -50%); /* 이미지를 정확히 중앙에 위치시킴 */
}
.bi .section4 {
  padding: 50px 10%;
  text-align: left;
}

.bi .section4 .content {
  display: flex;
  padding: 30px 0;
  align-items: center;
}

.bi .section4 .content strong {
  width: 100%;
}

.bi .section4 .content img {
  width: 100%;
  height: auto;
}

.bi .section4 .content .yigong-color {
  display: flex;
}

.bi .section4 .content .yigong-color div {
  padding-left: 50px;
}

.bi .section4 button {
  height: 70px;
  width: 70px;
  border-radius: 50px;
  border: none;
  margin: auto;
}

.intro .inner .section2 .left,
.intro .inner .section2 .right {
  flex: 1; /* 좌측과 우측 영역이 동일한 비율로 늘어나도록 설정 */
}

.intro .inner .section2 .left {
  text-align: left;
  margin: auto;
}

.intro .inner .section2 .left div {
  margin: 0 50px;
}

.intro .inner .section2 .left strong {
  font-size: 20px;
}

.intro .inner .section2 .left .btn-wrap {
  display: flex;
  gap: 10px;
}

.intro .inner .section2 .left .btn-wrap button {
  width: 100%;
  padding: 10px 5px;
  border-radius: 10px;
  color: #fff;
  border: none;
  background-color: #a57454;
  cursor: pointer;
}

.intro .section2 .naver-map .marker p {
  width: 110px;
  top: -100%;
  left: -250%;
  background-color: #a57454;
  border: 1px solid #a57454;
  border-radius: 20px;
  padding: 5px 10px;
  color: #fff;
  margin: 0;
  font-size: 12px;
}

.intro .section2 .naver-map .marker img {
  width: 25px;
}

/* 가맹절차 */

.procedure .section2 {
  padding: 100px 10%;
}

.procedure .section2 .contents-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: justify;
}

.procedure .section2 .contents-wrap .content {
  flex: 0 1 33%;
  text-align: center;
  overflow: hidden;
  border: 1px dotted #533c34;
  padding: 20px 0;
}

.procedure .section2 .contents-wrap .content:hover {
  background-color: #ede0d1;
  color: #533c34;
}

.procedure .section2 .contents-wrap .content div {
  width: 55px;
  font-size: 12px;
  background-color: #533c34;
  color: #fff;
  padding: 5px 10px;
  border-radius: 100px;
  margin: 10px auto;
}

.procedure .section2 .contents-wrap .content h4 {
  font-size: 20px;
  margin: 5px;
}
.procedure .section2 .contents-wrap .content p {
  font-size: 15px;
  margin: 10px 0;
  color: gray;
}

.procedure .inner .section3 {
  padding: 100px 0;
  background-color: #f5f4f0;
}

/* 개설비용 */

.cost .inner .section2 {
  padding: 50px 5% 100px 5%;
  background-color: #f5f4f0;
}

.cost .inner .section2 .button-wrap {
  margin-bottom: 50px;
  display: inline-flex;
  justify-content: center;
  border: 1px solid #533c34;
  border-radius: 10px;
  overflow: hidden;
}

.cost .inner .section2 .button-wrap button {
  border: none;
  padding: 20px 0;
  max-width: 700px;
  min-width: 200px;
  background-color: #f5f4f0;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: bold;
}

.cost .inner .section2 .button-wrap button.active {
  background-color: #533c34;
  color: #ffffff;
}

.cost .inner .section2 .table-wrap {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.cost .inner .section2 .table-column {
  flex: 1;
}

.cost table {
  border-spacing: 0;
  width: 100%;
  border-left: 1px dotted #4d4645;
}

.cost table thead {
  background-color: #533c34;
  color: #fff;
  font-weight: 600;
}

.cost table .content {
  font-size: 10px;
  font-weight: 600;
  color: gray;
}

.cost tr {
  height: 50px;
}

.cost table tbody td {
  text-align: center;
  align-items: center;
  border-bottom: 1px dotted #4d4645;
  border-right: 1px dotted #4d4645;
  font-size: 0.8rem;
  padding: 10px;
}

.cost td:first-child,
.cost th:first-child {
  font-weight: 600;
}

.cost tbody tr:hover,
.cost tbody tr:nth-child(3):hover ~ tr:nth-child(4),
.cost tbody tr:nth-child(3):hover ~ tr:nth-child(5) {
  background-color: #ede0d1;
}

.cost tfoot {
  background-color: #533c34;
  color: #fff;
  font-weight: 600;
}

.cost .inner .section2 .ment {
  padding-top: 30px;
  /* padding-left: 20px; */
  /* align-items: flex-start; */
}
.cost .inner .section2 .ment h4,
.cost .inner .section2 .ment p {
  text-align: left;
  margin: 0px;
}

.cost .inner .section2 .ment h4 {
  font-size: 15px;
  padding-bottom: 10px;
}

.cost .inner .section2 .ment p {
  font-size: 13px;
}

.inquiry .inner .section2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px 0;
}

.inquiry .inner .section2 div div {
  border-top: 3px solid #a6998a;
}

.inquiry .inner .section2 div p {
  font-size: 12px;
}

.inquiry .inner .section3 .con {
  padding: 30px 0;
}
.inquiry .inner .section3 .con .inner {
  width: 100%;
}

.inquiry .inner .section3 .con .animation {
  transform: translateY(0);
  opacity: 1;
}

.inquiry .inner .section3 .con li {
  position: relative;
  display: block;
  padding: 0 150px;
  margin-bottom: 10px;
}

.inquiry .inner .section3 .con li label {
  line-height: 40px;
  font-size: 14px;
  position: absolute;
  left: 0;
  display: block;
  line-height: 54px;
  color: #8e7c70;
  font-weight: 500;
}

.inquiry .inner .section3 .con li .d_wr textarea {
  height: 175px;
  padding-top: 17px;
  font-family: 'Noto Sans KR', sans-serif;
}

.inquiry .inner .section3 .con li .d_wr input {
  font-family: 'Noto Sans KR', sans-serif;
}

.inquiry .inner .section3 .con li .d_wr select {
  font-family: 'Noto Sans KR', sans-serif;
}

.inquiry .inner .section3 .con li .d_wr * {
  height: 40px;
  font-size: 13px;
  width: 100%;
  height: 54px;
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 8px;
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: 500;
  color: #1b1b1b;
  padding-left: 18px;
  display: block;
}

.inquiry .inner .section3 .chk_w .chk {
  display: flex;
  align-items: center; /* 세로 중앙 정렬 */
  justify-content: center; /* 가로 중앙 정렬 */
  padding: 10px 20px;
}

.inquiry .inner .section3 .chk_w .chk p {
  padding-left: 10px;
}

.inquiry .inner .section3 .chk p span {
  cursor: pointer;
}

.inquiry .inner .section3 .submit button {
  width: 500px;
  height: 70px;
  border-radius: 20px;
  border: none;
  background-color: #ab9f8f;
  cursor: pointer;
}

.inquiry .inner .section3 .submit button p {
  color: #fff;
  font-weight: 800;
  font-size: 20px;
}

.advantage .inner .section2 {
  padding: 50px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.advantage .inner .section2 {
  padding: 50px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.advantage .inner .section2 .content {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  flex: 0 1 35%;
  background: #f7f7f7;
  color: #333;
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.advantage .inner .section2 .content:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.advantage .inner .section2 .content img {
  width: 100%;
  height: auto;
}

.advantage .inner .section2 .content strong {
  color: #b68e5f;
  font-size: 1.5em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.advantage .inner .section2 .content div {
  margin: auto 0;
}

.advantage .inner .section2 .content p {
  line-height: 1.2;
}

.advantage .inner .section3 {
  font-size: 1.5em;
}

.newmenu {
  background-color: #fff;
}

.newmenu .inner2 {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.newmenu .section1 {
  font-size: 30px;
  padding: 50px 0;
}

.newmenu .section1 p {
  font-size: 18px;
  font-weight: 500;
  color: gray;
  margin-bottom: 0;
}

.newmenu .section2 {
  margin: 50px 5%;
  /* margin: 10%;
  display: flex;
  justify-content: left;
  flex-wrap: wrap; */
}

.newmenu .swiper-wrapper {
  transition-timing-function: linear;
}

.newmenu .swiper-slide {
  display: block;
  cursor: grab;
}

.newmenu .section2 img {
  width: 100%;
  margin: 0 auto;
}

.newmenu .section3 {
  padding-bottom: 50px;
}

.newmenu .section3 img {
  max-width: 100%;
}

.newmenu .section3 .sub-title {
  font-size: 30px;
  padding: 50px;
}

.newmenu .section4 {
  text-align: right;
  padding: 30px 0;
  border-bottom: 1px solid #b68e5f;
  margin: 0 5%;
}

.allMenu {
  background-color: #fff;
}

.allMenu .inner2 {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.allMenu .section1 p {
  font-size: 18px;
  font-weight: 800;
  color: gray;
}

.allMenu .section2 {
  margin: 0 10%;
}

.allMenu .section2 .select {
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.allMenu .section2 .select button {
  border: none;
  padding: 10px;
  background-color: #ede0d1;
  cursor: pointer;
  font-weight: 600;
  flex: 1;
}
.allMenu .section2 .select button.active {
  background-color: #533c34;
  color: #ede0d1;
}

.allMenu .section3 .sub-title {
  padding-top: 50px;
}

.allMenu .section4 {
  text-align: right;
  padding: 25px;
  margin: 0 10%;
  border-bottom: 1px solid #b68e5f;
}

.allMenu .section4 span {
  font-size: 15px;
}

.allMenu .section3 .sub-title strong {
  font-size: 20px;
}

.allMenu .section3 .sub-title p {
  font-weight: 800;
  color: gray;
}

.allMenu .section3 .content-wrap {
  margin-bottom: 50px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  margin: 50px 5%;
}
.allMenu .content-wrap .content {
  flex: 0 1 20%;
  margin: 30px 0;
}

.allMenu .content-wrap .content img {
  width: 80%;
}

.allMenu .content-wrap .content p {
  font-size: 0.9rem;
  margin: 20px;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #fff;
  opacity: 0.5;
  padding: 15px 5px;
  border-radius: 20px;
  color: black !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

.coffee .section1 p {
  font-size: 18px;
  font-weight: 800;
  color: gray;
}

.coffee .sub-title {
  margin-top: 50px;
}

.coffee .sub-title strong {
  font-size: 20px;
}

.coffee .sub-title p {
  font-weight: 800;
  color: gray;
}

.coffee .content-wrap {
  margin-bottom: 50px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  margin: 50px 10%;
}
.coffee .content-wrap .content {
  flex: 0 1 25%;
}

.coffee .content-wrap .content img {
  width: 200px;
  height: 200px;
}

.coffee .content-wrap .content p {
  margin: 0;
}

/* .store .section2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.store .section2 {
  text-align: justify;
}

.store .section2 .content {
  width: 300px;
  flex: 0 1 30%;
} */

.community .section2 {
  padding: 50px;
}

.community .section2 .search {
  text-align: right;
  padding-bottom: 10px;
}

.community .section2 .search input {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 8px;
  color: #bdb9b7;
  font-weight: 500;
  letter-spacing: -0.5px;
  height: 20px;
  padding-left: 10px;
}

.community .section2 .b_list table {
  width: 100%;
  border-top: 2px solid #ede0d1;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.community .section2 .b_list table th:nth-child(1) {
  width: 10%;
}
.community .section2 .b_list table th:nth-child(3) {
  width: 10%;
}

.community .section2 .b_list table th:nth-child(4) {
  width: 10%;
}

.community .section2 .b_list table th {
  border-bottom: 1px solid #ede0d1;
  height: 54px;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
  color: #413535;
  letter-spacing: -0.05em;
}

.community .section2 .b_list table td {
  text-align: center;
  border-bottom: 1px solid #ede0d1;
  font-size: 16px;
  color: #a69f9f;
  padding: 17px 0;
}

.community .section2 .b_list table td a {
  color: #a69f9f;
}

.community .section2 .paging div {
  font-size: 18px;
  padding: 20px;
}

.community .section2 .paging div span {
  padding: 10px;
}

.community .section2 .paging .on {
  font-size: 20px;
  color: #a57454;
}

/* 매장 */

.store {
  overflow: hidden;
}

.store .inner .sub-title {
  text-align: center;
  width: 100%;
  font-size: 2rem;
}

.store .inner .section2,
.store .inner .section3,
.store .inner .section4 {
  padding: 40px 0;
  border-bottom: 1px solid #b68e5f;
  margin: 0 5%;
}

.store .inner .sub-title {
  padding: 30px 0;
}

.store .content img {
  width: 100%;
  height: auto;
}

.store .section2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  text-align: justify;
}

.store .section2 .new-store-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.store .section2 .content {
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  border-bottom: 1px solid #ede0d1;
  padding-bottom: 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.store .section2 .content strong {
  justify-content: center;
  display: flex;
  margin: auto 0;
}

.store .section2 .content .img-wrap {
  overflow: hidden;
}

.store .content > strong {
  font-size: 1.1rem;
}

.store .content span {
  font-size: 0.9rem;
}

.store .content span {
  display: flex;
  justify-content: left;
  padding: 5px;
  word-wrap: break-word;
}

.store .section3 {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  /* height: 500px;
  overflow: scroll; */
}

.store .section3 .all-store-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.store .section3 .content {
  flex: 0 1 23%;
  border-radius: 10px;
  overflow: hidden;
  border-bottom: 1px solid #ede0d1;
  padding-bottom: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.store .section3 .content strong {
  justify-content: center;
  display: flex;
  width: 100%;
  margin: auto 0;
}

.store .section4 .mapStore {
  width: 300px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1000;
  background-color: #fff;
  border: lightgray 0.5px solid;
  border-radius: 10px;
}

.store .section4 .mapStore .map-top {
  /* background-color: #8e7c70; */
  display: flex;
  justify-content: center; /* 가로축 중앙 정렬 */
  align-items: center; /* 세로축 중앙 정렬 */
  height: 30px; /* 부모 div의 높이를 설정합니다 (필요한 경우) */
  position: relative;
  border-bottom: lightgray 1px solid;
}

.store .section4 .mapStore .map-top input {
  border: none;
  padding-left: 10px;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px;
}

.store .section4 .mapStore .map-top .search {
  width: 20px;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.store .section4 .mapStore .map-top .drop-down {
  width: 20px;
  position: absolute;
  right: 5px;
  cursor: pointer;
}

.store .section4 .mapStore .map-list {
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.store .section4 .mapStore .map-list.visible {
  max-height: 550px;
  overflow-y: scroll;
}

.store .section4 .mapStore .map-list .content .img {
  margin: auto 0;
}

.store .section4 .mapStore .map-list .content .img img {
  width: 30px;
  padding: 0 10px;
}

.store .section4 .mapStore .content {
  display: flex;
  padding: 5px;
  flex: 1 1;
  height: 100%;
  border-top: lightgray 1px solid;
  cursor: pointer;
}
.store .section4 .mapStore .content > div {
  height: 100% !important;
}

.store .section4 .mapStore .content img {
  width: 50px;
}

.store .section4 .mapStore .content div strong {
  display: flex;
}

.store .section4 .mapStore .content div {
  text-align: left;
}

/* 지도 확대축소 버튼, 수정 필요할수도 */
.store .section4 .content > div > div > div > div > div > div {
  margin: 0 10px 30px 0 !important;
}

.store .section4 .content .item .img_wrap img {
  width: 50px;
}

.store .section4 .naver-map .marker {
  position: absolute;
}

.store .section4 .naver-map .marker p {
  width: 110px;
  top: -100%;
  left: -250%;
  background-color: #a57454;
  border: 1px solid #a57454;
  border-radius: 20px;
  padding: 5px 10px;
  color: #fff;
  margin: 0;
  font-size: 12px;
}

.store .section4 .naver-map .marker img {
  width: 25px;
}

.community .noticeCont .section3 img {
  width: 80%;
  height: auto;
}

.community .noticeCont .navigation {
  padding: 30px 0;
}

.community .noticeCont .navigation a {
  display: flex;
  font-size: 20px;
  padding-left: 10px;
  padding: 15px 0;
  border-bottom: 1px solid lightgray;
}
.community .noticeCont .navigation a:first-child {
  border-top: 1px solid lightgray;
  align-items: center;
}

.community .noticeCont .navigation a + a {
  border-top: none;
}

.community .noticeCont .navigation span {
  padding-left: 40px;
}

.community .newsCont .section3 img {
  width: 70%;
  height: auto;
}

.community .newsCont .section3 p {
  text-align: left;
  padding: 30px 50px;
}

.community .event .section2 {
  padding: 100px 5%;
}

.community .event .section2 .list {
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.community .event .section2 .list .content {
  position: relative;
  max-width: 300px;
  flex: 0 1 30%;
}

.community .event .section2 .list .content .tag {
  position: absolute;
  left: 30px;
  z-index: 5;
}

.community .event .section2 .list .content .tag span {
  background-color: gray;
  display: block;
  padding: 5px 8px;
  width: 70px;
  color: white;
  font-size: 15px;
  border-radius: 0 0 12px 12px;
}

.community .event .section2 .list .content .tag.on span {
  background-color: #a57454;
  display: block;
  padding: 5px 8px;
  width: 70px;
  color: white;
  font-size: 15px;
  border-radius: 0 0 12px 12px;
}

.community .event .section2 .list .content .img {
  border-radius: 30px 30px 0 0;
  overflow: hidden;
}

.community .event .section2 .list .content .img img {
  max-width: 100%;
  transition: 0.8s;
  display: block;
}

.community .event .section2 .list .content .txt {
  padding: 10px;
  border-radius: 0 0 30px 30px;
  border: 1px solid gray;
  border-top: none;
}

.community .event .section2 .list .content .txt strong {
  font-size: 20px;
  display: block;
  height: 50px;
}

.footer {
  color: #ab9f8f;
  text-align: center;
  background-color: #533c34;
  padding: 50px;
  font-size: 70%;
}

.footer .section1 p {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px;
}
.footer .section2 {
  padding-top: 10px;
}
.footer .section2 p {
  margin: 3px;
}

.admin .section2 {
  height: 300px;
}

.admin form {
  width: 300px;
  margin: 50px auto;
  border: 1px solid lightgrey;
  border-radius: 20px;
  padding: 30px 0;
}

.admin .section2 form ul {
  padding-left: 0px;
}
.admin .section2 form ul li {
  margin-bottom: 10px;
}

.admin .section2 form ul li input {
  height: 30px;
}

.admin .section2 button {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background-color: #ab9f8f;
  cursor: pointer;
}

.admin .section2 button p {
  color: #fff;
  font-weight: 800;
  font-size: 15px;
  margin: 0;
}

.admin .section3 {
  padding: 50px 0;
}

.admin .section3 .b_list .logout {
  color: white;
  background: linear-gradient(45deg, #ede0d1, #533c34);
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  margin: 20px 30px;
}
.admin .section3 .b_list table {
  width: 100%;
  border-top: 2px solid #ede0d1;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.admin .section3 .b_list table th:nth-child(1) {
  width: 5%;
}

.admin .section3 .b_list table th:nth-child(2) {
  width: 10%;
}
.admin .section3 .b_list table th:nth-child(3) {
  width: 8%;
}
.admin .section3 .b_list table th:nth-child(4) {
  width: 8%;
}
.admin .section3 .b_list table th:nth-child(5) {
  width: 12%;
}
.admin .section3 .b_list table th:nth-child(6) {
  width: 60%;
}

.admin .section3 .b_list table th {
  border-bottom: 1px solid #ede0d1;
  height: 54px;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
  color: #413535;
  letter-spacing: -0.05em;
}

.admin .section3 .b_list table td {
  text-align: center;
  border-bottom: 1px solid #ede0d1;
  font-size: 16px;
  color: #a69f9f;
  padding: 17px 0;
}

/* 모달 */
/* 모달 */
/* 모달 */
/* 모달 */

.ad-modal {
  position: fixed;
  top: 25%; /* 화면 상단에서 약간 내려옴 */
  left: 0; /* 화면 왼쪽에 고정 */
  transform: none; /* 기존 가운데 정렬을 없앰 */
  width: auto;
  max-width: 90%;
  z-index: 1000;
  text-align: left; /* 텍스트 및 콘텐츠를 좌측 정렬 */
  margin-left: 20px; /* 좌측 여백 추가 (선택 사항) */
}

.ad-modal .content-wrap {
  display: flex; /* 가로로 나란히 배치 */
  gap: 20px; /* 콘텐츠 간 간격 */
  justify-content: flex-start; /* 콘텐츠를 좌측 정렬 */
  align-items: flex-start; /* 콘텐츠 상단 정렬 */
  flex-wrap: nowrap; /* 가로로 넘치더라도 줄바꿈하지 않음 */
}

.ad-modal .content {
  width: 400px;
  min-width: 400px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  overflow: hidden; /* 내부 요소가 넘치지 않도록 설정 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.ad-modal .img-wrap h1 {
  color: #533c34;
}

.ad-modal .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ad-modal .btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 5px 5px;
}

.ad-modal .btn-wrap .chk-wrap {
  display: flex;
  align-items: center;
}

.ad-modal .btn-wrap input {
  width: 20px;
  height: 20px;
}

.ad-modal .btn-wrap span {
  font-size: 14px;
  color: #555;
  margin-left: 10px;
}

.ad-modal .btn-wrap button {
  width: 80px;
  height: 30px;
  border-radius: 10px;
  border: none;
  color: #fff;
  background-color: #533c34;
  cursor: pointer;
  font-weight: bold;
}

/* 태블릿 */
/* 태블릿 */
/* 태블릿 */
/* 태블릿 */
/* 태블릿 */
/* 태블릿 */
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .sideFlow {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 100;
    scale: 70%;
  }

  .dashboard .section2 .content {
    width: 45%;
  }

  .dashboard .section2 .content img {
    width: 100px;
  }

  .header .inner .logo2 img {
    width: 250px;
  }

  .dashboard .section3 .slick-slide img {
    width: 150px;
    margin: 0 auto;
  }

  .bi .inner .section3 > div {
    height: 300px;
  }

  .bi .section4 strong {
    /* margin: auto 10px;
    width: 100px; */
  }

  .bi .section4 .content > div {
    /* width: 200px;
    display: flex; */
  }

  .cost .inner .section2 .ment {
    font-size: 15px;
    align-items: flex-start;
  }

  .cost .inner .section2 .ment h4,
  .cost .inner .section2 .ment p {
    text-align: left;
    margin: 0px;
  }

  .cost .inner .section2 img {
    width: 60%;
    height: auto;
  }
}

/* 모바일 */
/* 모바일 */
/* 모바일 */
/* 모바일 */
/* 모바일 */
/* 모바일 */
/* 모바일 */
@media screen and (max-width: 768px) {
  .header .inner .logo2 {
    position: fixed;
    left: 10px;
  }

  .header .inner .logo2 img {
    width: 200px;
  }

  .inner .section1 {
    font-size: 25px;
  }

  .brand-title img {
    height: 300px;
  }

  .brand-title .ment {
    font-size: 20px;
  }

  .sideFlow {
    position: fixed;
    right: 0%;
    bottom: 0%;
    z-index: 100;
    scale: 60%;
  }

  /* 대시보드 모바일 */

  .dashboard .section1 .name {
    font-size: 0.8em;
    top: 10%;
    left: 10%;
    line-height: 30px;
  }

  .dashboard .section2 .content {
    width: 45%;
  }

  .dashboard .section2 .content img {
    width: 50px;
  }

  .dashboard .section2 .sub-title {
    font-size: 0.8em;
    padding-top: 20px;
    padding-bottom: 80px;
  }

  .dashboard .section2 .sub-title strong {
    font-size: 2em;
  }

  .dashboard .section2 .content-wrap {
    padding: 10% 5%;
  }

  .dashboard .section2 .content svg {
    height: 40px;
  }

  .dashboard .section2 strong {
    font-size: 0.8em;
  }

  .dashboard .section2 a {
    padding: 5px 10px;
    border-radius: 10px;
    border: 2px solid #533c34;
    font-size: 0.5rem;
  }

  .dashboard .section3 {
    padding: 20% 0;
  }

  .dashboard .section3 .slick-slide img {
    width: 100px;
    margin: 0 auto;
  }

  .dashboard .section3 .sub-title strong {
    font-size: 1em;
  }

  .dashboard .section4 {
    padding: 20% 0 10% 0;
  }

  .dashboard .section4 .sub-title strong {
    flex: 4 1;
    font-size: 1em;
    padding: 5px 10px;
  }

  .dashboard .section4 .sub-title p {
    font-size: 0.5em;
  }

  .dashboard .section4 .swiper .contents .left .title {
    font-size: 1rem;
    color: #533c34;
  }

  .dashboard .section4 .swiper .contents .left h1 {
    font-size: 1rem;
  }

  .dashboard .section4 .swiper .contents .left P {
    margin-top: 10px;
    font-size: 0.5rem;
  }

  /* 이로운공간 모바일 */

  .brandIntroduce .section2 {
  }

  .brandIntroduce .section2 img {
    width: 100%;
    height: auto;
  }

  .brandIntroduce .section4 {
    position: relative;
    height: 500px; /* 원하는 높이 설정 */
    text-align: center;
    overflow: hidden; /* 이미지가 부모를 넘치지 않게 함 */
  }

  .brandIntroduce .section4 img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 이미지를 섹션 전체에 맞춤 */
    transform: translate(-50%, -50%); /* 이미지를 중앙에 배치 */
  }

  .brandIntroduce .section4 .ment1 {
    top: 50%;
    left: 20%;
  }

  .brandIntroduce .section4 .ment1 p {
    font-size: 3em;
  }

  .brandIntroduce .section4 .ment2 {
    top: 60%;
    left: 40%;
  }

  .brandIntroduce .section4 .ment2 p {
    font-size: 3em;
  }

  .brandIntroduce .section4 .ment3 {
    top: 30%;
    font-size: 1em;
  }

  .brandIntroduce .section4 .ment3 .big {
    font-size: 1.5em;
  }

  .brandIntroduce .section2 {
    position: relative;
    height: 500px;
    text-align: center;
    overflow: hidden; /* 이미지가 부모를 넘치지 않게 함 */
  }

  .brandIntroduce .section2 img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 이미지를 섹션 전체에 맞춤 */
    transform: translate(-50%, -50%); /* 이미지를 중앙에 배치 */
  }

  .brandIntroduce .section2 p {
    position: absolute;
    top: 25%;
    left: 5%;
    font-size: 0.7em;
  }

  .brandIntroduce .section5 .right p {
    font-size: 0.7em;
  }

  .brandIntroduce .section5 .right p span {
    font-size: 1em;
  }

  .brandIntroduce .section3 {
    position: relative;
    height: 500px;
    text-align: center;
    overflow: hidden; /* 이미지가 부모를 넘치지 않게 함 */
  }

  .brandIntroduce .section3 img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 이미지를 섹션 전체에 맞춤 */
    transform: translate(-50%, -50%); /* 이미지를 중앙에 배치 */
  }

  .brandIntroduce .section3 p {
    font-size: 1.5em;
    top: 30%;
  }

  .brandIntroduce .section3 p span {
    font-size: 1em;
  }

  /* BI 모바일 */

  .bi .inner .section2 strong {
    font-size: 20px;
  }

  .bi .inner .section2 strong strong {
    padding-left: 20px;
    font-size: 30px;
  }

  .bi .inner .section2 span {
    text-align: left;
  }

  .bi .inner .section2 span p:first-child {
    text-align: center;
  }
  .bi .inner .section3 {
    max-height: 300px;
    display: block;
    padding: 50px 0;
  }

  .bi .inner .section3 > div {
    width: 100%;
    height: 150px;
    margin: 20px 0;
  }

  .bi .section4 .content {
    width: 100%;
  }

  .bi .section4 .content > div {
    width: 100%;
    display: flex;
  }

  .bi .section4 .content > div img {
    width: 100%;
  }

  .bi .section4 .content .yigong-color {
    display: flex;
    width: 100%;
  }

  .bi .section4 button {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: none;
  }
  .bi .section4 .content .yigong-color {
    font-size: 0.5em;
  }

  .bi .section4 .content .yigong-color div {
    padding-left: 10px;
  }

  /* 오시는 길 모바일 */

  .intro .inner .section2 {
    display: block;
  }

  .intro .inner .section2 .left div {
    margin-left: 10px;
    margin-right: 0;
  }

  .intro .inner .section2 .left div p {
    font-size: 0.8em;
  }

  .intro .inner .section2 .left .btn-wrap {
    margin-left: 0;
    padding: 20px 0;
  }

  /* 가맹안내 - 가맹절차 모바일 */

  .procedure .section2 {
    padding: 50px 5%;
  }

  .procedure .section2 .contents-wrap {
    gap: 20px;
    justify-content: space-between;
  }

  .procedure .section2 .contents-wrap .content {
    width: 100%;
    flex: 1 1 100%;
  }

  .procedure .section2 .contents-wrap .content div {
    width: 50px;
    font-size: 8px;
    background-color: #533c34;
    color: #fff;
    padding: 5px;
    border-radius: 100px;
    margin: 10px auto;
  }

  .procedure .section2 .contents-wrap .content h4 {
    font-size: 15px;
    margin: 5px;
  }
  .procedure .section2 .contents-wrap .content p {
    font-size: 10px;
    margin: 10px 0;
    color: gray;
  }

  /* 개설비용 모바일 */

  .cost .inner .section2 {
    padding: 50px 0;
  }

  .cost .inner .section2 .button-wrap {
    width: 90%;
  }

  .cost .inner .section2 .button-wrap button {
    width: 90%;
  }

  .cost .inner .section2 .table-wrap {
    display: block;
  }

  .cost .inner .section2 .table-column {
    margin-bottom: 20px;
  }

  .cost .inner .section2 .table-wrap table {
    padding-bottom: 20px;
  }

  .cost .inner .section2 .ment {
    font-size: 15px;
    align-items: flex-start;
  }

  .cost .inner .section2 img {
    width: 90%;
    height: auto;
  }

  .cost .inner .section2 thead {
    font-size: 0.8em;
  }

  .cost td:first-child,
  .cost th:first-child {
    font-weight: 500;
  }

  .cost .inner .section2 tfoot {
    font-size: 0.8em;
  }

  .cost .inner .section2 .ment h4,
  .cost .inner .section2 .ment p {
    text-align: left;
    margin: 0px;
    padding-left: 10px;
    font-size: 12px;
  }

  .cost .inner .section2 .ment p {
    font-size: 10px;
  }

  /* 경쟁력 모바일 */

  .advantage .inner .section2 .content {
    flex: 0 1 100%;
    margin: 10px 0;
  }

  .advantage .inner .section2 {
    flex-direction: column;
    align-items: center;
  }

  .advantage .inner .section2 .content {
    flex: 0 1 100%;
    margin: 10px 0;
  }

  .advantage .inner .section2 .content img {
    order: 1;
  }

  .advantage .inner .section2 .content strong,
  .advantage .inner .section2 .content p {
    order: 2;
  }

  /* 가맹문의 모바일 */

  .inquiry .inner .section3 .con li .d_wr * {
    width: 100%;
    height: 35px;
    background: #fff;
    border: 1px solid #efefef;
    border-radius: 8px;
    font-size: 12px;
    letter-spacing: -0.5px;
    padding-left: 18px;
    display: block;
  }

  .inquiry .inner .section3 .con li .d_wr input {
    font-size: 12px;
    color: black;
    font-family: 'Noto Sans KR', sans-serif;
  }
  .inquiry .inner .section3 .con li .d_wr select {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 12px;
  }
  .inquiry .inner .section3 .con li .d_wr textarea {
    font-size: 12px;
    font-family: 'Noto Sans KR', sans-serif;
    color: black;
  }

  .inquiry .inner .section3 .con li .d_wr input::placeholder {
    font-size: 12px;
    color: #bdb9b7;
    font-family: 'Noto Sans KR', sans-serif;
  }
  .inquiry .inner .section3 .con li .d_wr textarea::placeholder {
    font-size: 12px;
    color: #bdb9b7;
    font-family: 'Noto Sans KR', sans-serif;
  }
  .inquiry .inner .section3 .con ul {
    padding: 0px;
    padding-right: 10px;
  }

  .inquiry .inner .section3 .con li label {
    display: none;
  }

  .inquiry .inner .section3 .con li {
    position: relative;
    display: block;
    padding: 0px;
    margin-bottom: 10px;
  }

  .inquiry .inner .section3 .submit button {
    width: 250px;
    height: 70px;
    border-radius: 20px;
    border: none;
    background-color: #ab9f8f;
  }

  .inquiry .inner .section3 .chk_w .chk {
    font-size: 11px;
  }

  /* 신메뉴 모바일 */

  .newmenu strong {
    font-size: 0.8em;
  }

  .newmenu p {
    font-size: 0.8em;
  }

  .newmenu .section1 {
    padding: 20px 0;
  }

  .newmenu .section2 {
    padding: 20px 0;
    margin: 0 5%;
  }

  .newmenu .section3 .swiper-slide .newmenu .section3 .swiper-slide img {
    width: 100%;
  }

  .newmenu .section3 span {
    font-size: 0.7em;
    width: 100%;
  }

  /* 전체메뉴 모바일 */

  .allMenu .section4 {
    margin: 0 5%;
    padding: 5% 0;
  }

  .allMenu .section2 {
    margin: 0 5%;
  }

  .allMenu .section2 .select {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
  }

  .allMenu .section2 .select button {
    width: 100%;
    font-size: 0.8em;
    background-color: white;
  }

  .allMenu .section3 .content-wrap {
    margin: 0 5%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  /* 매장 모바일 */

  .store .section2 .new-store-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .store .section3 .all-store-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .store .content span {
    font-size: 12px;
  }

  .store .section4 .content {
    padding-top: 100px;
  }

  .store .section4 .mapStore .map-list.visible {
    max-height: 350px;
  }

  .store .section4 .content > div {
    height: 400px !important;
  }

  .store .section4 .mapStore {
    top: -80px;
    width: 100%;
    left: 0;
  }

  .store .section4 .mapStore .map-top {
    height: 50px;
  }

  .store .section4 .mapStore .map-top .search {
    right: 40px;
  }

  .store .section4 .mapStore .map-top .drop-down {
    width: 30px;
  }

  /* 커뮤니티 모바일 */
  /* 공지사항 모바일 */

  .community .section2 {
    padding: 5%;
  }

  .community .section2 .b_list table td {
    padding: 5px 0;
    font-size: 0.7em;
  }

  .community .event .section2 .list .content .txt strong {
    font-size: 17px;
  }

  .community .event .section2 .list .content .txt p {
    font-size: 10px;
  }

  .community .section2 h1 {
    font-size: 1em;
  }

  .community .noticeCont .section2 {
    padding: 5% 0;
  }

  .community .section2 .date {
    font-size: 0.7em;
  }

  .community .noticeCont .section3 img {
    width: 90%;
  }

  .community .noticeCont .navigation span {
    padding-left: 20px;
    font-size: 0.7em;
  }

  /* 뉴스 모바일 */

  .community .newsCont .section2 {
    text-align: left;
  }

  .community .newsCont .section3 img {
    width: 90%;
  }

  .community .newsCont .section3 p {
    padding: 5%;
    font-size: 0.8em;
  }

  /* 푸터 모바일 */

  .footer .section1 .section2 {
    padding-left: 10px;
    font-size: 11px;
  }

  /* 문의하기 모달 모바일 */

  .modal-content-inquiry {
    width: 70%;
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1001;
    transform: translate(-50%, -50%);
  }

  .modal-content-inquiry li {
    margin: 5px 0;
    width: 100%;
  }

  .modal-content-inquiry .chk_w {
    padding: 5px;
    gap: 5px;
  }

  .modal-content-inquiry .chk_w .chk_btn {
    width: 24px;
  }

  .modal-content-inquiry .chk {
    margin: 10px 0;
    text-align: left;
    font-size: 0.7em;
  }

  /* 팝업모달 */

  .ad-modal {
    width: 80%;
    padding: 15px;
  }

  .ad-modal .content {
    width: 300px;
    min-width: 300px;
  }

  .ad-modal .btn-wrap button {
    width: fit-content;
    font-size: 16px;
    padding: 0px 10px;
  }

  .ad-modal .chk-wrap span {
    min-width: 0px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .ad-modal {
    top: 10%;
    width: 80%;
    padding: 10px;
  }

  .ad-modal .content-wrap {
    display: block;
    position: relative;
  }

  .ad-modal .content {
    width: 100%;
    position: absolute !important;
  }

  .ad-modal .btn-wrap button {
    font-size: 12px;
  }

  .ad-modal .chk-wrap span {
    font-size: 12px;
  }
}
