ol,
ul,
li {
  list-style: none;
}

a {
  color: #333;
  text-decoration: none;
}
