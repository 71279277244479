@charset "utf-8";

/* header */
.header {
  width: 100%;
  height: 110px;
  z-index: 1000000;
}
.header h1 img {
  height: 65px;
}
.header .inner {
  background-color: #ede0d1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: unset;
  padding: 0 60px;
}
.header .inner > div.header-menu-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.header .inner > div.header-menu-box.show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.header .menu-bg {
  width: 100%;
  height: 200px;
  background-color: #fafafa;
  position: absolute;
  top: 110px;
  display: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  left: 0;
  z-index: 10;
}
.header-menu {
  height: 100%;
}

.header-menu:hover ol,
.header-menu:hover .menu-bg {
  display: block;
}

.header-menu:after {
  content: '';
  display: block;
  clear: both;
}
.header-menu > li {
  width: 120px;
  float: left;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-menu > li > a {
  font-size: 17px;
  color: #202020;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1.6px;
}
/* .header-menu > li ol:before{content:""; width:13px; height:13px; background-color:#ede0d1; position:Absolute; top:-7px; left:50%; transform:translateX(-50%) rotate(45deg);   } */
.header-menu > li ol {
  display: none;
  background-color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 50%;
  top: calc(100% + 1px);
  transform: translateX(-50%);
  width: 120px;
  text-align: center;
  padding: 20px 0;
  z-index: 10;
  height: 199px;
}
.header-menu > li:first-child ol {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.header-menu > li.on > a {
  color: #ede0d1;
}
.header-menu > li.on ol {
  background-color: #ede0d1;
}
.header-menu > li.on ol a {
  color: #fff;
}
.header-menu > li ol a {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  transition: all 0.3s;
  display: block;
  padding: 10px 0;
}

.header-menu > li:hover > a {
  color: #baafa4;
}

.header-menu > li:hover > ol {
  background-color: #ede0d1;
}
.header-menu > li ol li:hover a {
  background-color: #baafa4;
  color: white;
}

.header-menu > li ol li:last-child {
  margin-bottom: 0;
}
.header-sns {
  margin-left: 100px;
  display: flex;
  align-items: center;
}
.header-sns li {
  display: inline-block;
  margin-right: 10px;
}
.header-sns li:last-child {
  margin-right: unset;
}
.header-sns li a {
  opacity: 0.6;
  transition: all 0.3s;
}
.header-sns li:hover a {
  opacity: 1;
}
.header-sns li a {
  width: 38px;
  height: 38px;
  background-color: #686969;
  display: flex;
  border-radius: 100%;
  font-size: 20px;
  color: #fff;
  align-items: center;
  justify-content: center;
}
.header-sns li a img {
  width: 20px;
}
.header .hm {
  display: none;
  height: 20px;
  width: 30px;
  position: relative;
  cursor: pointer;
  z-index: 100000000;
}
.header .hm span {
  width: 100%;
  height: 3px;
  background-color: #b68e5f;
  display: block;
  position: absolute;
  left: 0;
  border-radius: 10%;
  transition: all 0.3s;
}
.header .hm span:nth-of-type(1) {
  top: 0;
}
.header .hm span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
}
.header .hm span:nth-of-type(3) {
  bottom: 0;
}

.header .hm.on span:nth-of-type(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.header .hm.on span:nth-of-type(2) {
  opacity: 0;
}
.header .hm.on span:nth-of-type(3) {
  bottom: 50%;
  transform: translateY(50%) rotate(-45deg);
}

.header-menu-mob {
  display: none;
}
.header .inner .on {
  /* display: block; */
  transform: translate(0, 0);
}

.header-menu-mob {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 80%;
  max-width: 320px;
  background-color: rgba(255, 255, 255, 0.9);
  padding-top: 140px;
  padding-bottom: 60px;
  padding-left: 50px;
  z-index: 999;
  transform: translate(100%, 0);
  transition: transform 0.8s;
  /* overflow-y: scroll; */
}
.header-menu-mob .menu-item {
  cursor: pointer;
}
.header-menu-mob .sub-menu {
  display: none;
}

.header-menu-mob > ul {
  /* border: 1px black solid; */
  padding-left: 0px;
  margin: 0 20%;
}

.header-menu-mob > ul > li {
  width: 100%;
  float: unset;
  display: block;
  /* text-align: center; */
  margin-bottom: 30px;
}

.header-menu-mob > ul > li > ol > li {
  font-size: 14px;
  color: #666;
  line-height: 24px;
  opacity: 0.6;
  transition: opacity.3s;
}

.header .inner .on > ul > li span:hover {
  color: #b68e5f;
  /* text-decoration: underline #b68e5f; 밑줄과 색상을 동시에 설정 */
}

.header-menu-mob .sub-menu.active {
  display: block;
}
.header-menu-mob .sub-menu.active > li:hover {
  background-color: #ede0d1;
}

.header-menu-mob .sub-menu.active #hd_pop {
  position: fixed;
  width: 380px;
  height: 550px;
  z-index: 1000;
  right: 15%;
  top: 23%;
}
#hd_pop a {
  color: transparent;
}
#hd_pop #popClose {
  position: absolute;
  width: 25px;
  height: 25px;
  right: 0;
  top: -32px;
  background-color: #ede0d1;
  color: #fff;
  border-radius: 100%;
  line-height: 25px;
  text-align: Center;
}
#hd_pop .swiper-slide .hd_pops_con {
  border-radius: 20px;
  overflow: hidden;
}
#hd_pop .swiper-pagination {
  bottom: 0px;
}
#hd_pop .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ede0d1;
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .header {
    height: 100px;
  }
  .header .hm {
    display: block;
  }
  .header-menu {
    height: auto;
  }
  .header .inner > div.header-menu-box {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 1000000;
    padding: 100px 0;
    box-sizing: border-box;
  }

  .header .inner > div.header-menu-box.show {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 1000000;
    padding: 100px 0;
    box-sizing: border-box;
  }
  .header-menu > li {
    width: 100%;
    float: unset;
    display: block;
    text-align: center;
  }
  .header-menu > li > a {
    padding: 10px 0;
    display: block;
  }
  .menu-bg {
    display: none;
  }
  .header-menu > li ol {
    width: 100%;
    position: unset;
    transform: unset;
    height: auto;
    padding: 0;
    z-index: 1000000;
  }
  .header-sns {
    justify-content: center;
    padding: 50px 0;
    margin-left: 0;
  }

  .header-menu-mob {
    display: block;
  }
}
@media screen and (max-width: 799px) {
  body {
    padding-top: 50px;
  }
  .header {
    height: 50px;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10000;
    background: #ede0d1;
  }
  .header h1 img {
    height: 41px;
  }
  .header .hm {
    display: block;
    height: 18px;
    width: 20px;
    position: fixed;
    right: 20px;
  }
  .header-menu {
    height: auto;
  }
  .header .inner > div.header-menu-box {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 1000000;
    padding: 100px 0;
    box-sizing: border-box;
  }

  .header .inner > div.header-menu-box.show {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 1000000;
    padding: 100px 0;
    box-sizing: border-box;
  }
  .header-menu > li {
    width: 100%;
    float: unset;
    display: block;
    text-align: center;
  }
  .header-menu > li > a {
    padding: 10px 0;
    display: block;
  }
  .menu-bg {
    display: none;
  }
  .header-menu > li ol {
    width: 100%;
    position: unset;
    transform: unset;
    height: auto;
    padding: 0;
    z-index: 1000000;
  }
  .header-sns {
    justify-content: center;
    padding: 50px 0;
    margin-left: 0;
  }
  #hd_pop {
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%);
    top: 12%;
    width: 90%;
    height: auto;
    right: unset;
  }
  #hd_pop .swiper-slide img {
    width: 100%;
  }
  #hd_pop .swiper-pagination {
    bottom: 10px;
  }

  .header-menu-mob {
    display: block;
  }
}

.quick-menu {
  position: fixed;
  right: 70px;
  z-index: 100;
  bottom: 100px;
  box-shadow: 10px 10px 15px rgb(0 0 0 / 10%);
}
.quick-menu li {
  width: 120px;
  height: 120px;
  background-color: #ede0d1;
  color: #fff;
  border: 1px solid #ede0d1;
}
.quick-menu li:nth-of-type(2) {
  background-color: #fff;
}
.quick-menu li a {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.quick-menu li a p {
  font-size: 16px;
  color: #fff;
  margin-top: 10px;
  font-weight: 600;
}
.quick-menu li:nth-of-type(2) p {
  color: #ede0d1;
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .quick-menu {
    right: 20px;
    bottom: 70px;
    box-shadow: unset;
  }
  .quick-menu li {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    border-radius: 100%;
    box-shadow: 10px 10px 15px rgb(0 0 0 / 10%);
  }
  .quick-menu li a p {
    display: none;
  }
  .quick-menu li img {
    width: 50%;
  }
}
@media screen and (max-width: 799px) {
  .quick-menu {
    right: 20px;
    bottom: 70px;
    box-shadow: unset;
  }
  .quick-menu li {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    border-radius: 100%;
    box-shadow: 10px 10px 15px rgb(0 0 0 / 10%);
  }
  .quick-menu li a p {
    display: none;
  }
  .quick-menu li img {
    width: 50%;
  }
}

/* 공통 레이아웃 */
#subvisual {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  text-align: left;
}
/*#subvisual .inner{display: flex; flex-direction: column; justify-content: center;  height: 100%;}*/
#subvisual .inner {
}
#subvisual h2 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 60px;
  color: #fff;
  margin-bottom: 20px;
  padding-top: 150px;
}
#subvisual p {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);
}
.sub-tabmenu {
  width: 100%;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  height: 50px;
  position: absolute;
  background-color: #533c34;
  z-index: 9;
  margin-top: -54px;
}
.sub-tabmenu li {
  float: left;
}
.sub-tabmenu .inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sub-tabmenu .inner ul {
  margin: 0;
}

.sub-tabmenu .inner ul li {
  padding: 13px 25px;
  position: relative;
}
.sub-tabmenu .inner ul li a {
  font-size: 20px;
  color: #fff;
  font-weight: 900;
  word-break: keep-all;
}
.sub-tabmenu .inner ul li.on {
  color: black;
  z-index: 100;
  background-color: #ede0d1;
}

.sub-tabmenu .inner ul li.on a {
  color: black;
}
.sub-tabmenu .inner ul li:after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background-color: transparent;
  position: absolute;
  left: 0;
  bottom: -11px;
}
/* .sub-tabmenu .inner ul li.on:after {
  background-color: #ede0d1;
} */
.sub-tabmenu .inner ol li {
  font-size: 14px;
  text-transform: uppercase;
}
.sub-tabmenu .inner ol li:first-child,
.sub-tabmenu .inner ol li:first-child a {
  color: #fff;
  opacity: 0.5;
}
.sub-tabmenu .inner ol li:first-child a img {
  margin-right: 5px;
}
.sub-tabmenu .inner ol li p {
  color: #fff;
  opacity: 1;
}
.sub-tabmenu .inner ol li i {
  margin: 0 10px;
}

@media screen and (min-width: 1280px) {
  #container .inner {
    padding: 50px 0;
  }
  .inner {
    position: relative;
    max-width: 1280px !important;
    /* margin: 0 auto; */
  }
}
@media screen and (min-width: 800px) and (max-width: 1000px) {
  .sub-tabmenu .inner ul li a {
    /* font-size: 12px; */
  }
  .header-menu-mob .sub-menu.active {
    padding-left: 0px;
  }
}
@media screen and (max-width: 799px) {
  .inner {
    position: relative;
    max-width: 90% !important;
    margin: 0 auto;
  }
  #subvisual h2 {
    font-size: 34px;
    padding-top: 85px;
    letter-spacing: 2px;
  }
  #subvisual p {
    font-size: 15px;
    line-height: 20px;
  }
  #subvisual p span {
    display: block;
  }
  #subvisual {
    height: 300px;
  }
  .sub-tabmenu .inner ul li a {
    font-size: 12px;
  }
  .sub-tabmenu .inner ul li {
    margin-right: 5px;
  }
  .sub-tabmenu {
    height: 40px;
    margin-top: -44px;
  }
  .sub-tabmenu .inner ul li:after {
    bottom: -12px;
  }
  .header-menu-mob .sub-menu.active {
    padding-left: 0px;
  }

  .sub-tabmenu .inner ul li {
    padding: 10px;
  }
}

/* 서브 - 콘텐츠 */
#container {
  display: block;
  position: relative;
  background: #fff;
}
.sub_inner {
  position: relative;
  max-width: 1200px !important;
  margin: 0 auto;
  padding: 100px 0;
  font-size: 17px;
  line-height: 30px;
  color: #777;
  white-space: normal;
  word-break: keep-all;
}

@media screen and (min-width: 1200px) {
  .sub_inner {
    position: relative;
    max-width: 1200px !important;
    margin: 0 auto;
    padding: 80px 0;
  }
}
@media screen and (min-width: 800px) and (max-width: 1000px) {
  .sub_inner {
    position: relative;
    max-width: 100% !important;
    padding: 80px 50px;
  }
}
@media screen and (max-width: 799px) {
  .sub_inner {
    position: relative;
    max-width: 100% !important;
    padding: 40px 20px;
    box-sizing: border-box;
  }

  .sub-tabmenu .inner ul {
    padding: 0;
  }
}

/* 서브페이지 - 타이틀 */
#container .sub-title {
  color: #111;
  font-size: 32px;
  text-align: left;
  padding-bottom: 50px;
  margin-top: 0px;
}

/* 서브페이지 - 이용약관, 개인정보취금방침, 이메일수집거부 */
#container .sub-privacy {
  padding: 20px 0;
}
#container .sub-privacy .subject {
  color: #1a1a1a;
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 500;
}
#container .sub-privacy .content {
  color: #9a9a9a;
  font-size: 13px;
  margin-bottom: 50px;
  line-height: 150%;
  text-align: justify;
}

/* 링크모듈 */
#quick-link {
  position: fixed;
  right: 20px;
  bottom: 70px;
  z-index: 9999;
}
#quick-link a {
  display: block;
  margin-top: 10px;
}
#quick-link img {
  width: 40px;
  border-radius: 100%;
}

#scroll-link {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  line-height: 20px;
  font-size: 10px;
  color: #fff;
  text-align: center;
  background: #000;
  border-radius: 100px;
  padding: 10px;
  cursor: pointer;
  opacity: 0.6;
  z-index: 99999;
}
#scroll-link:hover {
  filter: alpha(opacity=80);
  -khtml-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
